<script setup lang="ts">
const articleCounts = useArticleReadCounts();

const cards = useArticleCards();

await cards.suspense();

const mostReadArticles = computed(() =>
  LEVELS.reduce(
    (acc, level) => {
      const counts = articleCounts.data.value?.counts ?? {};
      acc[level] =
        cards.data.value
          ?.filter((card) => card.levels.includes(level))
          .sort((a, b) => (counts[b.id] || 0) - (counts[a.id] || 0))
          .slice(0, 3) ?? [];
      return acc;
    },
    {} as Record<Level, ArticleCard[]>,
  ),
);

const hasData = computed(
  () => Object.values(mostReadArticles.value).flat().length > 0,
);
</script>

<template>
  <section v-if="hasData" class="relative bg-coral-20 pb-24 text-green-50">
    <h3 aria-hidden="true" class="most-read__title headline relative z-[1] mx-auto my-16 w-80 text-center text-3xl">
      Mest leste <span class="bg-coral-20 py-2">artikler</span>
      <UiRotateWithScroll class="absolute -right-12 -top-8 z-[-1]">
        <IconsGraphicsMostReadSpinner />
      </UiRotateWithScroll>
    </h3>
    <h3 class="sr-only">
      Mest leste artikler
    </h3>

    <div class="most-read__overview mx-4 my-auto flex max-w-content flex-col flex-wrap gap-8 xs:m-auto xs:flex-row xs:gap-0">
      <template v-for="option in LEVEL_OPTIONS" :key="option.value">
        <div
          v-if="mostReadArticles[option.value].length > 0"
          class="overview__column flex flex-[1] flex-col"
        >
          <h4 class="p-4 font-normal capitalize sm:p-7">
            {{ option.title }}
          </h4>
          <div
            v-for="article in mostReadArticles[option.value]"
            :key="article.id"
          >
            <CardsArticleSimple :article="article" />
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<style scoped lang="scss">
.overview__column {
  & > * {
    border-bottom: 1px dotted theme("colors.green.50");

    &:nth-child(1) {
      border-top: none;
      border-bottom: 2px solid theme("colors.green.50");

      @media screen and (min-width: $extra-small) {
        margin-top: 0;
      }
    }

    &:nth-child(4) {
      border-bottom: none;
    }
  }

  @media screen and (min-width: $extra-small) {
    &:not(:last-child) {
      border-right: 1px dotted theme("colors.green.50");
    }
  }
}
</style>
