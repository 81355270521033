<script setup lang="ts">
const { data: homeData, error, suspense: waitForHomeData } = useHomepage();

await waitForHomeData();

useHead({
  bodyAttrs: {
    class: "bg-green-50",
  },
});
</script>

<template>
  <div>
    <UiCommonsError v-if="error" :error="error" />
    <main
      id="main-content"
      class="m-auto flex w-screen flex-col justify-center"
    >
      <HomeHero
        :products="homeData?.productCards"
        :title="homeData?.title"
      />
      <UiArticlesHighlighted
        v-if="homeData?.highlightedArticles?.articles?.length"
        :data="homeData?.highlightedArticles"
      />
      <ClientOnly>
        <UiStripesEvents />
        <UiStripesArticles
          class="yellow-light-green pb-16"
          title="Les siste nytt fra Aschehoug skole"
          :show-all="false"
          :no-top-margin="true"
          :range="[0, 3]"
        />
        <UiArticlesMostRead />
        <UiArticlesHomeMagazine />
      </ClientOnly>
      <UiStripesPodcasts
        v-if="homeData?.podcastCards.length"
        :podcasts="homeData?.podcastCards"
      />
      <CardsAdvCard v-if="homeData?.advCard" :adv="homeData?.advCard" />
      <CtaNewsletter />
    </main>
  </div>
</template>
