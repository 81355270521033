<script setup lang="ts">
import { KsIcon } from "@aschehoug/kloss";
import { faLongArrowRight } from "@fortawesome/pro-light-svg-icons";

const props = defineProps<{ adv: AdvCard }>();

const classes = computed(() => {
  switch (props.adv.theme) {
    case "yellow":
      return {
        wrapper: "bg-yellow-30 text-red-50",
        container: "bg-yellow-20",
      };
    case "light-blue":
      return {
        wrapper: "bg-beige-20 text-green-50",
        container: "bg-seagreen-10",
      };
    default:
      return {
        wrapper: "",
        container: "",
      };
  }
});
</script>

<template>
  <section :class="classes.wrapper">
    <div class="m-0 flex max-w-content flex-wrap gap-0 sm:mx-auto sm:my-16" :class="classes.container">
      <div class="mx-24 my-16 flex min-w-56 max-w-[50%] flex-[1] flex-col items-start justify-center gap-6">
        <span
          v-if="adv.tags"
          class="text-sm font-light uppercase tracking-wider"
          v-text="adv.tags"
        />
        <img
          v-if="adv.icon"
          :src="adv.icon.img"
          :alt="adv.icon.alt"
          aria-hidden="true"
          class="scale-95"
        />
        <h3>{{ adv.title }}</h3>
        <div v-html="adv.text" />
        <NuxtLink
          v-for="item in adv.link"
          :key="item.url"
          :to="item.url"
          class="flex -translate-x-12 items-center gap-2 font-bold no-underline transition-transform duration-300 first-of-type:mb-[-1.4rem] first-of-type:mt-4 hover:translate-x-0"
        >
          <KsIcon
            :icon="faLongArrowRight"
            aria-hidden="true"
            :scale="1.4"
          />
          {{ item?.label }}
        </NuxtLink>
      </div>
      <img
        v-if="adv.img"
        :src="adv.img.img"
        :alt="adv.img.alt"
        class="min-w-[50%] flex-[1]"
      />
    </div>
  </section>
</template>
