import type { ItemRelation } from "#shared/utils/crystallize";
import type { Crystallize } from "#shared/utils/crystallize-spec";

export function toAdvCard(item: ItemRelation): AdvCard {
  const adv: AdvCard = {
    link: [],
  };

  if (item.components) {
    for (const component of item.components) {
      if (!component.content) {
        continue;
      }

      const id = component.id as Crystallize.ComponentId<"reklame">;

      switch (id) {
        case "tittel":
          adv.title = getSingleLine(component);
          break;
        case "etikett-tittel":
          adv.linksTitle = getSingleLine(component);
          break;
        case "tag-linje":
          adv.tags = getSingleLine(component);
          break;
        case "farge-kombinasjon":
          adv.theme = getSelections(
            component,
          )[0] as Crystallize.AdvCardColorTheme;
          break;
        case "lenke": {
          const links = getRepeatableChunks(component);

          for (const linkChunks of links) {
            if (!linkChunks) {
              continue;
            }

            const linkGroup: AdvCardLink = {};
            for (const chunk of linkChunks) {
              if (!chunk.content) {
                continue;
              }

              const id = chunk.id as Crystallize.ChunkId<"reklame", "lenke">;

              switch (id) {
                case "url":
                  linkGroup.url = getSingleLine(chunk);
                  break;
                case "etikett":
                  linkGroup.label = getSingleLine(chunk);
                  break;
              }
            }
            adv.link.push(linkGroup);
          }
          break;
        }
        case "bilde":
          adv.img = getSingleImage(component, 500);
          break;
        case "ikon":
          adv.icon = getSingleImage(component, 500);
          break;
        case "tekst":
          adv.text = getRichText(component);
          break;
      }
    }
  }

  return adv;
}
