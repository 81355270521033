<script setup lang="ts">
import { KsIcon } from "@aschehoug/kloss";
import { faLongArrowRight } from "@fortawesome/pro-light-svg-icons";

const { data: events, suspense } = useEvents();

await suspense();

const relevantEvents = computed(() => {
  const today = new Date();
  const lastRelevantDate = addMonths(today, 5);
  return events.value
    ?.filter((event) => {
      const start = new Date(event.dates[0] ?? today);
      const end = new Date(event.dates[event.dates.length - 1] ?? today);
      return (
        (start > today || end > today) &&
        (start < lastRelevantDate || end < lastRelevantDate) &&
        event.highlighted === true
      );
    })
    .sort(byFirstDate("asc"))
    .slice(0, 3);
});
</script>

<template>
  <section v-if="relevantEvents?.length" class="events-stripe">
    <h2 class="events-stripe__title">
      Arrangementer
    </h2>
    <div class="events-stripe__container">
      <CardsEventSimple
        v-for="event in relevantEvents"
        :key="event.id"
        :event="event"
      />
    </div>
    <NuxtLink class="events-stripe__link" to="/arrangementer">
      <KsIcon :icon="faLongArrowRight" class="events-stripe__link-icon" />
      <span class="events-stripe__link-label">Se alle arrangementer</span>
    </NuxtLink>
    <img
      src="/graphics/cream_wave_corner.svg"
      class="events-stripe__right-corner"
      aria-hidden="true"
      alt=""
    />
  </section>
</template>

<style scoped lang="scss">
.events-stripe {
  position: relative;
  width: 100%;
  min-height: 8rem;
  background-color: theme("colors.beige.10");

  .events-stripe__right-corner {
    position: absolute;
    bottom: 0;
    right: 0;
    display: none;

    @media screen and (min-width: $small) {
      display: block;
    }
  }

  .events-stripe__title,
  .events-stripe__container {
    max-width: theme("spacing.content");
  }
  .events-stripe__container {
    margin: 3rem 1rem;
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    width: 100%;

    @media screen and (min-width: $small) {
      margin: 2rem auto 4rem auto;
    }
  }

  .events-stripe__title {
    text-transform: uppercase;
    color: theme("colors.green.50");
    letter-spacing: 3px;
    margin: 3rem auto 0 1rem;
    font-size: pixelsToRem(20);
    padding-left: 1rem;
    line-height: pixelsToRem(28);

    @media screen and (min-width: $small) {
      font-size: pixelsToRem(30);
      margin: 5.6rem auto 2rem auto;
    }
  }

  .events-stripe__link {
    margin: 2rem 1rem 4rem 2.3rem;
    max-width: pixelsToRem(270);
    display: flex;
    gap: 2rem;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    color: theme("colors.green.50");
    align-items: center;

    &-icon,
    &-label,
    &-icon svg {
      transition: all 0.2s ease-in-out;
    }

    &-icon {
      transform: scale(2);

      @media screen and (min-width: $small) {
        margin-left: 1.4rem;
      }
    }

    &-label {
      font-size: pixelsToRem(18);
      font-weight: 500;

      @media screen and (min-width: $extra-small) {
        font-size: pixelsToRem(20);
      }
    }

    &:hover {
      text-decoration: underline;

      .events-stripe__link-icon {
        transform: translateX(0.8rem) scale(2);
      }

      .events-stripe__link-label {
        transform: translateX(0.8rem);
      }
    }

    @media screen and (min-width: $small) {
      max-width: theme("spacing.content");
      margin: -2rem auto 4rem auto;
    }
  }
}
</style>
